:root {
  --ion-color-primary: #FF7B54;
  --ion-color-primary-rgb: 255,123,84;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e06c4a;
  --ion-color-primary-tint: #ff8865;

  --ion-color-secondary: #FFB26B;
  --ion-color-secondary-rgb: 255,178,107;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e09d5e;
  --ion-color-secondary-tint: #ffba7a;

  --ion-color-tertiary: #FFD56F;
  --ion-color-tertiary-rgb: 255,213,111;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #e0bb62;
  --ion-color-tertiary-tint: #ffd97d;



  --ion-background-color: #FFFAF4;
}



@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #FF7B54;
    --ion-color-primary-rgb: 255,123,84;
    --ion-color-primary-contrast: #FFFAF4;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #e06c4a;
    --ion-color-primary-tint: #ff8865;

    --ion-color-secondary: #FFB26B;
    --ion-color-secondary-rgb: 255,178,107;
    --ion-color-secondary-contrast: #FFFAF4;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #e09d5e;
    --ion-color-secondary-tint: #ffba7a;

    --ion-color-tertiary: #FFD56F;
    --ion-color-tertiary-rgb: 255,213,111;
    --ion-color-tertiary-contrast: #FFFAF4;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #e0bb62;
    --ion-color-tertiary-tint: #ffd97d;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #FFFAF4;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #FFFAF4;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #FFFAF4;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #FFFAF4;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #FFFAF4;
    --ion-color-light-tint: #383a3e;

    --ion-background-color: #FFFAF4;

    div#root {
      --ion-item-background: #FFFAF4;
      --ion-toolbar-background: #FFFAF4;
      --ion-tab-bar-background: #FFFAF4;
      --ion-card-background: #FFFAF4;
    }

    ion-segment {
      color: #FF7B54;
    }
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #FFFAF4;
    --ion-background-color-rgb: 255, 250, 244;

    --ion-text-color: #FF7B54;
    --ion-text-color-rgb: 255,123,84;

    --ion-color-step-50: #FFFAF4;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #FFFAF4;

    --ion-card-background: #F2E3DB;
    --ion-background-color: #FFFAF4;


  }

  .ios ion-modal {
    --ion-background-color: #FFFAF4;
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #FFFAF4;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #FFFAF4;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
    --ion-background-color: #FFFAF4;

  }
}