.sidebar-menu {
  h2#user {
    font-weight: bold;
    min-height: 20px;
    padding-left: 10px;
  }
  ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
  }
  ion-item.selected {
    --color: var(--ion-color-primary);
  }
  ion-content {
    --background: #FFFAF4;
  }
  &.md {
    ion-content {
      --padding-start: 8px;
      --padding-end: 8px;
      --padding-top: 20px;
      --padding-bottom: 20px;
    }
    ion-list {

      ion-list-header {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 18px;
        color: #FF7B54;
        min-height: 26px;
      }

      --background: FFFAF4;
    }
    ion-list-header,
    ion-note {
      --padding-left: 10px;
    }
    ion-item {
      --padding-start: 8px;
      --padding-end: 0;
      --inner-padding-end: 8px;
      border-radius: 4px;
      ion-icon {
        color: #616e7e;
      }
      ion-label {
        font-weight: 500;
      }
      &.selected {
        --background: rgba(var(--ion-color-primary-rgb), 0.14);
        ion-icon {
          color: var(--ion-color-primary);
        }
      }
    }
  }

  &.ios {
    ion-content {
      --padding-bottom: 20px;
    }
    ion-list {
      padding: 20px 0 0 0;
      &#cheerleader-list ion-list-header {
        margin-bottom: 8px;
      }
    }
    ion-note {
      line-height: 24px;
      margin-bottom: 20px;
      margin-bottom: 8px;
    }
    ion-list-header,
    ion-note {
      padding-left: 16px;
      padding-right: 16px;
    }
    ion-item {
      --padding-start: 16px;
      --padding-end: 16px;
      --min-height: 50px;
      ion-icon {
        font-size: 24px;
        color: #73849a;
      }
      &.selected ion-icon {
        color: var(--ion-color-primary);
      }
    }
  }
}
