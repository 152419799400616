.max-height-1 {
 --min-height: 1rem
}
.max-height-2 {
 --min-height: 2rem
}
.max-height-3 {
 --min-height: 3rem
}
.max-height-4 {
 --min-height: 4rem
}

.padding-y-0 {
 padding-top: 0;
 padding-bottom: 0;
}
