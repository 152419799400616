ion-card.loginCard {
  flex-direction: column;
  display: flex;
  max-width: 450px;

  ion-card-header {
    display: flex;
    justify-content: center;
  }

  ion-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
