input {
  background: #f6f6f6 !important;
  margin-left: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.textAreaBorder {
  div {
    textarea {
      border: solid !important;
      padding: 10px !important;
    }
  }
}