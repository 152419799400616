@import "svg/textSvg.css";
@import "./loginCard";
@import "./login";
@import "./flexUtils";
@import "./sizing";
@import "menu";
@import "./cheerleader";
@import "./input";
@import "./theme";

.circleAroundTextSlider {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #FFFAF4;
  border: 2px solid #000;
  color: #000;
  display: grid;
}